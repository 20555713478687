// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN TAB */

.ant-tabs-card-bar {
  .ant-tabs-tab {
    margin: 0 0 16px 0 !important;
    padding: 8px 24px 8px 24px !important;
    border: none !important;
    border-radius: 0 !important;
    background-color: transparent !important;
    line-height: 19.5px !important;
    height: 100% !important;
  }
  .ant-tabs-ink-bar {
    visibility: visible !important;
  }
}
