// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN MODAL */
.ant-modal-fullscreen {
  .ant-modal {
    top: 0;
    height: 100% !important;
  }
  .ant-modal-content {
    height: 100% !important;
    min-height: 100vh !important;
  }
  .ant-modal-close {
    outline: none !important;
  }
  .ant-modal-body {
    padding: 0;
    height: 100% !important;
  }
}

.ant-modal-fullscreen-contents {
  .ant-modal {
    top: 0;
    height: 100% !important;
  }
  .ant-modal-content {
    height: auto !important;
    min-height: 100vh !important;
  }
  .ant-modal-close {
    outline: none !important;
  }
  .ant-modal-body {
    padding: 0;
    height: 100% !important;
  }
}

.ant-modal-fullscreen-component {
  .ant-modal {
    top: 0;
    height: 100% !important;
  }
  .ant-modal-content {
    padding: 20px;
    border-radius: 0;
    background-color: #F2F4F8;
    min-height: 100vh !important;
  }
  .ant-modal-close {
    outline: none !important;
  }
  .ant-modal-body {
    padding: 0;
    height: 100% !important;
  }
}
