// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN INPUT */

.ant-input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f2f4f8;
  cursor: not-allowed;
  opacity: 1;
}

.form-frame .ant-form-item-label {
  margin-right: 1.5rem;
}

.form-time .ant-form-item-label {
  margin-right: 2.1rem;
}

.form-frame-rate .ant-form-item-label {
  margin-right: 2.5rem;
}

.ant-divider-horizontal {
  margin: 15px 0;
}
