// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN TAB */

.ant-list {
  background-color: #fff;
  margin-bottom: 1rem !important;
}
